import { FC, ReactElement, useEffect } from 'react';
import { connectHits } from 'react-instantsearch-dom';

import { IPropsAlgoliaCustomHits } from './models';

export const AlgoliaCustomHits: FC<IPropsAlgoliaCustomHits> = ({
  hits,
  saveAlgoliaHitsResponse,
  handleHitsResponseActivated,
  isHitsResponseActivated,
}): ReactElement | null => {
  useEffect(() => {
    if (!isHitsResponseActivated && hits.length) {
      handleHitsResponseActivated(true);
    }
  }, [isHitsResponseActivated, hits]);

  useEffect(() => {
    saveAlgoliaHitsResponse(hits);
  }, [hits]);

  return null;
};

const ConnectedAlgoliaCustomHits = connectHits(AlgoliaCustomHits);

export default ConnectedAlgoliaCustomHits;
