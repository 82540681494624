import React, { FC } from 'react';
import classNames from 'classnames';

import { IPropsTitleWithLine } from './models';

import './TitleWithLine.scss';

const TitleWithLine: FC<IPropsTitleWithLine> = ({ children, className, Tag = 'h2' }) => (
  <Tag
    data-testid="TitleWithLine"
    className={classNames('title-with-line', {
      [className || '']: className,
    })}
  >
    {children}
  </Tag>
);
export default TitleWithLine;
