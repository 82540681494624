import React, { FC } from 'react';

import InfoCard from 'components/elements/InfoCard';
import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsSearchNoResults } from './models';

import './SearchNoResults.scss';

const SearchNoResults: FC<IPropsSearchNoResults> = ({
  queryToDisplay,
  searchNoResults: [{ title, titleForEmptySearch, text, image }],
}) => (
  <InfoCard
    title={
      <>
        {`${queryToDisplay ? title : titleForEmptySearch} `}
        {queryToDisplay ? <>&quot;{queryToDisplay}&quot;</> : null}
      </>
    }
  >
    <div data-testid="SearchNoResults" className="search-no-results">
      {text ? <DangerouslySetInnerHtml html={text} className="search-no-results__text" /> : null}
      <GatsbyImage image={image} className="search-no-results__image" objectFit="contain" />
    </div>
  </InfoCard>
);
export default SearchNoResults;
