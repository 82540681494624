import React, { FC } from 'react';
import classNames from 'classnames';

import { IPropsTitle } from './models';

import './Title.scss';

const Title: FC<IPropsTitle> = ({ children, className, Tag = 'h2' }) => (
  <Tag
    data-testid="Title"
    className={classNames('header-title', {
      [className || '']: className,
    })}
  >
    {children}
  </Tag>
);
export default Title;
