import { FC, ReactElement, useEffect } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

import { IPropsCustomSearchBox } from './models';

export const CustomSearchBox: FC<IPropsCustomSearchBox> = ({
  isSearchStalled,
  handleLoadingStatus,
}): ReactElement | null => {
  useEffect(() => {
    setTimeout(() => handleLoadingStatus(isSearchStalled), 200);
  }, [isSearchStalled]);

  return null;
};

const ConnectedCustomSearchBox = connectSearchBox(CustomSearchBox);

export default ConnectedCustomSearchBox;
