import React, { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import classNames from 'classnames';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';

import AlgoliaCustomHits from 'components/algolia/AlgoliaCustomHits';
import ConnectedCustomSearchBox from 'components/algolia/CustomSearchBox';
import { HitsPerPage, IAlgoliaSearchStateData } from 'utils/algoliaFilters';

import { DefaultSearchBoxState } from './constants';
import Helpers from './helpers';
import { IPropsAlgoliaSearchBox } from './models';

import './AlgoliaSearchBox.scss';

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_PUBLIC_KEY as string
);

/**
 * To prevent searches from happening in certain use cases,
 * we need to wrap a proxy around the Algolia search client.
 *
 * This proxy allows us to perform logic before calling the search method
 */
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

const AlgoliaSearchBox: FC<IPropsAlgoliaSearchBox> = ({
  indexName,
  lang,
  handleLoadingStatus,
  isLoading,
  saveResultItems,
}): ReactElement | null => {
  const [searchState, setSearchState] = useState<IAlgoliaSearchStateData>(DefaultSearchBoxState);

  const {
    itemsToRender,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
  } = useAlgoliaResponse([]);

  const savedQuery = Helpers.getSavedQueryToSet() || '';

  const handleActiveFiltersIdsOnLoad = useCallback((query: string) => {
    handleAlgoliaFiltersUsed(true);
    setSearchState({ ...DefaultSearchBoxState, query });
  }, []);

  useEffect(() => {
    handleActiveFiltersIdsOnLoad(savedQuery);
  }, [savedQuery]);

  useEffect(() => {
    if (!searchState.query) {
      handleAlgoliaFiltersUsed(false);
    } else {
      handleAlgoliaFiltersUsed(true);
    }
  }, [searchState.query]);

  useEffect(() => {
    if (!itemsToRender) {
      return;
    }
    saveResultItems(itemsToRender);

    handleHitsResponseActivated(true);
  }, [itemsToRender]);

  const handleOnSearchStateChange = useCallback((newSearchState: IAlgoliaSearchStateData) => {
    setSearchState(newSearchState);
  }, []);

  return (
    <section
      data-testid="AlgoliaSearchBox"
      className={classNames('algolia-search-box', {
        loading: isLoading,
      })}
    >
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        refresh
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={handleOnSearchStateChange}
      >
        <Configure
          filters={Helpers.getDefaultFiltersParams(lang)}
          hitsPerPage={HitsPerPage}
          analytics={false}
          distinct
          maxValuesPerFacet={HitsPerPage}
        />
        <ConnectedCustomSearchBox handleLoadingStatus={handleLoadingStatus} />
        <AlgoliaCustomHits
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
        />
      </InstantSearch>
    </section>
  );
};

export default AlgoliaSearchBox;
