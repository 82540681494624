import React, { FC } from 'react';
import classNames from 'classnames';

import Container from 'layout/Container';
import BreadCrumbs from 'components/elements/BreadCrumbs';
import Title from 'components/elements/Title';

import { IPropsPageIntro } from './models';

import './PageIntro.scss';

const PageIntro: FC<IPropsPageIntro> = ({ title, breadcrumbs }) => (
  <Container className="page-intro" data-testid="PageIntro">
    <BreadCrumbs
      breadcrumbs={breadcrumbs}
      className={classNames('', {
        'without-title': !title,
      })}
    />
    {title ? <Title Tag="h1">{title}</Title> : null}
  </Container>
);
export default PageIntro;
