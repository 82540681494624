import { ALGOLIA_URL_PARAMS } from 'utils/algoliaFilters';
import { getLocationQueryStringParam } from 'utils/browser';

const getDefaultFiltersParams = (lang: string, additionalFilterParams?: string): string =>
  `lang:${lang}${additionalFilterParams || ''}`;

const getSavedQueryToSet = (): string | null =>
  getLocationQueryStringParam(ALGOLIA_URL_PARAMS.querySearch, 'string');

const Helpers = {
  getDefaultFiltersParams,
  getSavedQueryToSet,
};

export default Helpers;
