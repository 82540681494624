import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import IconCustom from 'components/helpers/IconCustom';

import { IBreadCrumbs } from './models';

import './BreadCrumbs.scss';

const BreadCrumbs: FC<IBreadCrumbs> = ({ breadcrumbs, className }) => (
  <ul
    data-testid="BreadCrumbs"
    className={classNames('breadcrumbs', {
      [className || '']: className,
    })}
  >
    {breadcrumbs.map(({ name, link }) => (
      <li key={`list-${name}`}>
        {link ? (
          <Link className="breadcrumbs__link" to={link}>
            <span className="link__label">{name}</span>
            <IconCustom icon="arrow" className="link__icon" />
          </Link>
        ) : (
          <span className="link__label">{name}</span>
        )}
      </li>
    ))}
  </ul>
);
export default BreadCrumbs;
