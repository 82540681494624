import React, { FC, lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { Hit } from 'react-instantsearch-core';
import { graphql } from 'gatsby';
import { Algolia } from '@shared/types';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import AlgoliaSearchBox from 'components/algolia/AlgoliaSearchBox';
import PageIntro from 'components/elements/PageIntro';
import SearchNoResults from 'components/sections/SearchNoResults';
import { ALGOLIA_URL_PARAMS } from 'utils/algoliaFilters';
import { getLocationQueryStringParam } from 'utils/browser';

import { IPropsSearchPage } from './models';

import './SearchPage.scss';

const SearchResultsLoadable = lazy(() => import('components/sections/SearchResults'));
const renderLoader = () => null;

const SearchPage: FC<IPropsSearchPage> = ({ data: { pageData }, pageContext }) => {
  const [resultItems, setResultItems] = useState<Hit<Algolia.ICustomHit>[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [queryToDisplay, setQueryToDisplay] = useState<string>('');

  const { seo, urls, searchResults, searchNoResults } = pageData;
  const { breadcrumbs, lang } = pageContext;

  const queryUrlParam = getLocationQueryStringParam(ALGOLIA_URL_PARAMS.querySearch, 'string');

  useEffect(() => {
    setQueryToDisplay(queryUrlParam);
  }, [queryUrlParam]);

  const handleLoadingStatus = useCallback((value: boolean) => {
    setLoading(value);
  }, []);

  const saveResultItems = useCallback((data: Hit<Algolia.ICustomHit>[]) => {
    setResultItems(data);
  }, []);

  const searchNoResultsView = (
    <SearchNoResults searchNoResults={searchNoResults} queryToDisplay={queryToDisplay} />
  );

  return (
    <Layout data-testid="SearchPage" seo={seo} lang={lang} urls={urls} className="search-page">
      <PageIntro breadcrumbs={breadcrumbs} />
      <Container>
        <AlgoliaSearchBox
          indexName={`${process.env.GATSBY_ALGOLIA_INDEX}-search`}
          lang={lang}
          handleLoadingStatus={handleLoadingStatus}
          isLoading={isLoading}
          saveResultItems={saveResultItems}
        />

        {!queryUrlParam ? searchNoResultsView : null}
        {queryUrlParam && !isLoading && resultItems?.length ? (
          <Suspense fallback={renderLoader()}>
            <SearchResultsLoadable
              searchResults={searchResults}
              items={resultItems}
              queryToDisplay={queryToDisplay}
            />
          </Suspense>
        ) : null}
        {queryUrlParam && !isLoading && !resultItems?.length ? searchNoResultsView : null}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String!) {
    pageData: searchPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentSearchPage
    }
  }
`;

export default SearchPage;
