import React, { FC } from 'react';
import classNames from 'classnames';

import Container from 'layout/Container';
import TitleWithLine from 'components/elements/TitleWithLine';

import { IPropsInfoCard } from './models';

import './InfoCard.scss';

const InfoCard: FC<IPropsInfoCard> = ({ title, children, className }) => (
  <Container data-testid="InfoCard" className="info-card">
    <div className={classNames('info-card__wrapper', {}, className)}>
      {title ? <TitleWithLine Tag="h2">{title}</TitleWithLine> : null}
      {children}
    </div>
  </Container>
);
export default InfoCard;
