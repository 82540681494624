import { useCallback, useEffect, useState } from 'react';
import { Hit } from 'react-instantsearch-core';
import { Algolia } from '@shared/types';

import { IHookAlgoliaResponse } from './models';

const useAlgoliaResponse = (defaultItems: Hit<Algolia.ICustomHit>[]): IHookAlgoliaResponse => {
  const [isAlgoliaFiltersUsed, setAlgoliaFiltersUsed] = useState<boolean>(false);
  const [isHitsResponseActivated, setHitsResponseActivated] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] = useState<Hit<Algolia.ICustomHit>[]>([]);

  const itemsToRender = isAlgoliaFiltersUsed && isHitsResponseActivated ? filteredItems : null;

  useEffect(() => {
    setFilteredItems(defaultItems);
  }, []);

  const saveAlgoliaHitsResponse = useCallback((items: Hit<Algolia.ICustomHit>[]) => {
    setFilteredItems(items);
  }, []);

  const handleAlgoliaFiltersUsed = useCallback((value: boolean) => {
    setAlgoliaFiltersUsed(value);
  }, []);

  const handleHitsResponseActivated = useCallback((value: boolean) => {
    setHitsResponseActivated(value);
  }, []);

  return {
    itemsToRender,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
  };
};

export default useAlgoliaResponse;
